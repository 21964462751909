import { useState, useEffect } from "react";
import registerData from "./../data/registerDetail.json";
import api, { setAuthorization } from "../lib/api";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { auth } from "../lib/firebase";

const COUNTRY_CODE = "+61";
const Filed = ({
  itemdata,
  addArray,
  addMessage,
  nextStep,
  registerDetailNext,
  phone,
  setPhone,
  verificationCode,
  setVerificationCode,
  verificationId,
  setVerificationId,
  getProfileData,
  profileUpdate,
  questionData,
  questionNext,
  questionAdd,
  getQuestion,
  pickUpType,
  setPickUpType,
  sameData,
  setSameData,
}) => {
  const [seconds, setSeconds] = useState(30);
  const [activeBtn, setActiveBtn] = useState("");
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const [value, setValue] = useState("");
  const [temp, setTemp] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userWantsToChangeAddress, setUserWantsToChangeAddress] = useState(false);
  const handleInputChange = (event, key) => {
    const inputValue = event.target.value;
    if (key === "phone") {
      setPhone(inputValue);
    }
    setValue(inputValue);
    setIsValid(value.trim() !== "");
  };

  const loadingToggle = () => {
    setIsLoading(false);
  };

  const onSubmit = async (keyName, options, event) => {
    setIsSubmitted(true);
    if (keyName === "phone") {
      // Create a temporary variable to hold the modified value
      let tempValue = value;

      // Replace all spaces with empty string
      tempValue = tempValue.replace(/\s/g, "");

      // Remove country code
      tempValue = tempValue.replace(COUNTRY_CODE, "");

      // Update the state with the modified value
      setValue(tempValue);

      // Mobile number verify function
      const mobileNumberRegex = /^\d{8,12}$/;
      if (mobileNumberRegex.test(tempValue)) {
        setPhone(tempValue);
        return handleSendOtp();
      } else {
        addArray("Please enter a valid Mobile Number");
      }
    } else if (keyName === "otp") {
      // otp verify function
      const otpRegex = /^[0-9]{6}$/;
      if (otpRegex.test(value)) {
        return handleVerifyOtp();
      }
      return addArray("Please enter a valid 6-digit OTP");
    } else if (keyName.includes("updateprofile")) {
      // user update function
      const updatekey = keyName.split("_")[0];
      if (updatekey === "email") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          return addArray("Please Enter Valid Email Address");
        }
      }
      profileUpdate(updatekey, value);
      if (keyName.includes("option")) {
        if (updatekey === "profileSubmit")
          return checkOption(updatekey, options);
        checkOption(updatekey, options, true);
        return;
      }

      if (keyName === registerData[registerData.length - 1]?.data.key) {
        return nextStep();
      }

      registerDetailNext();
    } else if (keyName.includes("(question)")) {
      // question selection function
      const key = keyName
        .split(" ")[0]
        .replace(/-/g, " ")
        .replace("(question)", "");

      questionAdd(key, options);
      if (keyName === questionData[questionData.length - 1]?.data.key) {
        return nextStep();
      }
      if (keyName.includes("option")) {
        return checkOption(key, options);
      }
      if (keyName.includes("address") || keyName.includes("city") || keyName.includes("state") || keyName.includes("zipcode")) {
        const addressComponent = keyName.split(" ")[0];
        questionAdd(addressComponent, value);
        let addressData = JSON.parse(localStorage.getItem("addressData")) || {};

        addressData[addressComponent] = value;

        // Store the updated object back into localStorage
        localStorage.setItem("addressData", JSON.stringify(addressData));

        if (keyName.includes("zipcode")) {
          // After zip code, show the full address for confirmation
          const fullAddress = `${addressData.address}, ${addressData.city}, ${addressData.state} ${addressData.zipcode}`;
          localStorage.setItem("fullAddress", fullAddress);
          return questionNext(
            0,
            `Your current Address is: <i>${fullAddress}</i> <br />
            Are you sure this is a valid address?`
          );
        } else {
            // Move to the next question
            return questionNext();

        }
      }
      if (pickUpType === "Pickup") {
        questionNext();
      } else {
        questionNext(2);
      }
    } else if (keyName.includes("option")) {
      const Key = keyName.split(" ")[0];
      checkOption(Key, options);
    } else {
      nextStep();
    }
  };

  const handleResendOTPSend = async () => {
    try {
      const appVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container1",
        {}
      );
      const phoneNumber = `${COUNTRY_CODE}${phone}`;
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier
      );
      setVerificationId(confirmationResult);
      setSeconds(30);
      // return nextStep();
    } catch (error) {
      console.error("Error sending OTP:", error.message);
      return addArray("Phone verification request failed.");
    }
  };

  const handleSendOtp = async () => {
    try {
      const appVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {size: "invisible"}
      );
      const phoneNumber = `${COUNTRY_CODE}${value}`;
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier
      );
      const response = await api.post("/api/v1/auth/login", { phone: value });
      if (response.data.success) {
        setVerificationId(confirmationResult);
        return nextStep();
      } else {
        return addArray("Phone verification request failed.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error.message);
      return addArray("Phone verification request failed.");
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const user = await verificationId.confirm(value);
      const response = await api.post("/api/v1/auth/validate-user", {
        phone: phone,
        otp: value,
      });
      if (response.data.success) {
        setAuthorization(response.data.result.token);
        if (response.data.result.isRegistered) {
          return nextStep();
        }
        return registerDetailNext();
      }
      return addArray(response.data.message);
    } catch (error) {
      addArray(error.message);
    }
  };

  const handleGetLocation = (key, isProfileUpdate) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const address = `${latitude},${longitude}`;
          if (isProfileUpdate) {
            localStorage.setItem("address", address);
            profileUpdate(key, address);
          } else {
            localStorage.setItem("address", address);
            questionAdd(key, address);
          }
        },
        (error) => {
          addArray(error.message);
        }
      );
    } else {
      addArray("Geolocation is not supported by your browser");
    }
  };

  const checkOption = async (keyName, value, isProfileUpdate) => {
    let dataArray = JSON.parse(localStorage.getItem("myData")) || [];
    const address_value = localStorage.getItem("address");
    switch (keyName) {
      case "address":
        if (isProfileUpdate) {
          if (value === "auto") {
            handleGetLocation(keyName, isProfileUpdate);
            registerDetailNext(2);
            return;
          }
          registerDetailNext();
        } else {
          if (value === "auto") {
            handleGetLocation(keyName, isProfileUpdate);
            return questionNext(2);
          }
          questionNext();
        }
        break;
      case "confirmLocation":
        if (value === "yes") {
          const fullAddress = localStorage.getItem("fullAddress");
          questionAdd("address", fullAddress);
          return questionNext();
        } else {
          // If the user says no, go back to the address input
          localStorage.removeItem("addressData");
          setUserWantsToChangeAddress(true);
          return questionNext(-10); // Adjust this number based on the number of steps to go back
        }
      case "profileSubmit":
        updateProfile();
        break;
      case "questionSubmit":
        questionSubmit();
        break;
      case "newrequest":
        if (value === "yes") {
          setPickUpType("Pickup");
          if (address_value) {
            return questionNext();
          }
          return questionNext(4);
        }
        setPickUpType("Walk In");
        return questionNext(8);
        // endMessage("Thank you 😊");
        break;
      case "changeAddress":
        if (value === "yes") {
          if (address_value) {
            questionAdd("address", address_value);
            return questionNext(4);
          }
        }
        questionNext();
        break;
      case "addressType":
        if (value === "yes") {
          if (address_value) {
            questionAdd("address", address_value);
            return questionNext(
              0,
              `Your saved Address is: <i>${address_value}</i> <br />
            Are your sure this is valid address ?`
            );
          }
        }
        return questionNext(2);
      case "confirmLocationSave":
        if (isProfileUpdate) {
          if (value === "yes") {
            return registerDetailNext();
          }
          return registerDetailNext(-2);
        } else {
          if (value === "yes") {
            return questionNext(4);
          }
          return questionNext();
        }
      default:
        break;
    }
  };

  const updateProfile = async () => {
    try {
      const addressData = JSON.parse(localStorage.getItem("addressData"));
      const response = await api.put("/api/v1/auth/profile-create", {
        fullname: getProfileData.name,
        email: getProfileData.email,
        address: addressData.address,
        city: addressData.city,
        state: addressData.state,
        zipcode: addressData.zipcode,
      });

      if (response.data.success) {
        localStorage.setItem("address", getProfileData.address);
        return nextStep();
      } else {
        return registerDetailNext();
      }
    } catch (error) {}
  };

  const questionSubmit = async () => {
    const address_value = localStorage.getItem("address");
    const submitData = { ...getQuestion };
    const addressData = JSON.parse(localStorage.getItem("addressData"));

    delete submitData.confirmLocation;
    delete submitData.confirmLocationSave;
    try {
      const response = await api.post("/api/v1/create-new-submission", {
        no_of_bottles: submitData["how many bottles you have ?"],
        pickUpType: pickUpType,
        info: { ...submitData },
        ...addressData,
      });
      if (response.status === 200) {
        return nextStep();
      } else {
        addArray("Try Again.");
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (itemdata.type === "input" && itemdata.data.key === "otp") {
      const intervalId = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prevSeconds) => prevSeconds - 1);
        } else {
          clearInterval(intervalId);
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [seconds]);

  return (
    <>
      {itemdata.type === "text" && (
        <>
          <div className="media media-chat">
            <div className="media-body">
              <p
                className="botColor text-white"
                dangerouslySetInnerHTML={{
                  __html: itemdata.data.text.replace(/\(end\)/, ""),
                }}
              ></p>
            </div>
          </div>
          {itemdata.data.text.includes("(end)") && (
            <div className="media media-chat">
              <div className="media-body">
                <a
                  href="https://www.facebook.com/Harrysreturnnearn"
                  target="_blank"
                >
                  <button
                    type="button"
                    className={`btn userButton facebook-icon mx-2 my-2`}
                  >
                    <i className="fa fa-facebook"></i>
                  </button>
                </a>
                <a
                  href="https://www.instagram.com/harrysreturnandearn/"
                  target="_blank"
                >
                  <button
                    type="button"
                    className={`btn userButton instagram-icon mx-2 my-2`}
                  >
                    <i className="fa fa-instagram"></i>
                  </button>
                </a>
              </div>
            </div>
          )}
        </>
      )}

      {itemdata.type === "input" && itemdata.data.type_required === "text" && (
        <div className="media media-chat media-chat-reverse mt-2">
          <div className="media-body">
            <div className="input-group">
              <input
                className="form-control py-4"
                type="search"
                disabled={isSubmitted}
                placeholder={itemdata.data.description}
                onChange={(e) => handleInputChange(e, itemdata.data.key)}
              />
              <span className="input-group-append">
                <button
                  className="btn userButton"
                  type="button"
                  disabled={isSubmitted}
                  onClick={() => {
                    if (value) {
                      setIsLoading(true);
                      onSubmit(itemdata.data.key, value).then(() => {
                        loadingToggle();
                      });
                    }
                  }}
                >
                  {isLoading ? (
                    <div className="spinner-border " role="status"></div>
                  ) : !isSubmitted ? (
                    <i className="fa fa-solid fa-arrow-right"></i>
                  ) : (
                    <i className="fa fa-solid fa-check text-success"></i>
                  )}
                </button>
              </span>
            </div>
            {itemdata.data.key === "otp" && (
              <p className="text-body-secondary">
                {isSubmitted !== true ? (
                  seconds > 0 ? (
                    `Resend OTP After ${seconds}`
                  ) : (
                    <button
                      onClick={() => {
                        setIsOTPLoading(true);
                        handleResendOTPSend().then(() => {
                          setIsOTPLoading(false);
                        });
                      }}
                      className="btn userButton"
                    >
                      {isLoading ? (
                        <div className="spinner-border " role="status"></div>
                      ) : (
                        "Resend OTP"
                      )}
                    </button>
                  )
                ) : (
                  ""
                )}
              </p>
            )}
          </div>

          <div id="recaptcha-container"></div>
          <div id="recaptcha-container1"></div>
        </div>
      )}
      {itemdata.type === "input" &&
        itemdata.data.type_required === "number" && (
          <div className="media media-chat media-chat-reverse mt-2">
            <div className="media-body">
              <div className="input-group">
                <input
                  className="form-control py-4"
                  type={itemdata.data.type_required}
                  disabled={isSubmitted}
                  placeholder={itemdata.data.description}
                  onChange={(e) => handleInputChange(e, itemdata.data.key)}
                />
                <span className="input-group-append">
                  <button
                    className="btn userButton"
                    type="button"
                    disabled={isSubmitted}
                    onClick={() => {
                      if (value) {
                        setIsLoading(true);
                        onSubmit(itemdata.data.key, value).then(() => {
                          loadingToggle();
                        });
                      }
                    }}
                  >
                    {isLoading ? (
                      <div className="spinner-border " role="status"></div>
                    ) : !isSubmitted ? (
                      <i className="fa fa-solid fa-arrow-right"></i>
                    ) : (
                      <i className="fa fa-solid fa-check text-success"></i>
                    )}
                  </button>
                </span>
              </div>
            </div>

            <div id="recaptcha-container"></div>
            <div id="recaptcha-container1"></div>
          </div>
        )}
      {itemdata.type === "input" &&
        itemdata.data.type_required === "textarea" && (
          <div className="media media-chat media-chat-reverse">
            <div className="media-body">
              <div className="input-group">
                <textarea
                  className="form-control py-2"
                  type="search"
                  placeholder={itemdata.data.description}
                  onChange={(e) => handleInputChange(e, itemdata.data.key)}
                  disabled={isSubmitted}
                  required
                />
                <span className="input-group-append">
                  <button
                    className="btn userButton"
                    type="button"
                    disabled={isSubmitted}
                    onClick={() => {
                      if (value) {
                        setIsLoading(true);
                        onSubmit(itemdata.data.key).then(() => {
                          loadingToggle();
                        });
                      }
                    }}
                  >
                    <i className="fa fa-solid fa-arrow-right"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
        )}
      {itemdata.type === "input" &&
        itemdata.data.type_required === "option" && (
          <div className="media media-chat media-chat-reverse">
            <div
              className={`media-body ${
                itemdata.data.key ===
                  "which-character-is-missing-a-b-d-?(question)" && "block_line"
              }`}
            >
              {itemdata.data.options.map((item) => (
                <button
                  key={item.value}
                  type="button"
                  disabled={isSubmitted}
                  onClick={() => {
                    setIsLoading(true);
                    setActiveBtn(item.value);
                    onSubmit(itemdata.data.key, item.value).then(() => {
                      loadingToggle();
                    });
                  }}
                  className={`btn userButton mx-2 my-2 ${
                    activeBtn === item.value && "active"
                  }`}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
        )}
    </>
  );
};

export default Filed;